import { useQuery, useQueryClient, useMutation } from 'react-query';
import { CartService } from '@app/services/cart.service';

import { LocalStorageService } from '@app/services/localstorage.service';
import { getQueryKey } from '@app/utilities/common.utility';
import { ClevertapService } from '@app/services/clevertap.service';
import { postEventToMobile } from '@app/services/mobile.bridge';
interface Options {
  onSuccess?: (data: CartService.Cart | undefined) => void;
  onError?: () => void;
}

export default function useAddToCart(
  isBoxProduct: boolean,
  options?: { onSuccess: Options['onSuccess']; onError: Options['onError'] }
) {
  const cart_item_count_query = getQueryKey('cart_item_count_query', isBoxProduct);
  const cart_query = getQueryKey('cart_query', isBoxProduct);
  const queryClient = useQueryClient();

  return useMutation(CartService.addItem, {
    onSuccess: (data) => {
      options?.onSuccess?.(data);
      if (data?.items) {
        const count = data?.items?.length || 0;
        postEventToMobile('cart_count', { count, type: isBoxProduct ? 'box' : 'cart' });
        // update inMemory
        queryClient.setQueryData(cart_item_count_query, count);
        queryClient.setQueryData(cart_query, data);
        // update localstorage
        LocalStorageService.writeToStorage(cart_item_count_query, JSON.stringify(count));
        LocalStorageService.writeToStorage(cart_query, JSON.stringify(data));
      }
      // ClevertapService.notificationPermission();
    },
    onMutate: async () => {},
    onError: (_error, _variables, context) => {
      options?.onError?.();
    },
    onSettled: async () => {
      // finally
    },
  });
}
