import {
  WISHLIST_ADD_ERROR,
  WISHLIST_ADD_LOADING,
  WISHLIST_ADD_RESET,
  WISHLIST_ADD_SUCCESS,
  WISHLIST_REMOVE_ERROR,
  WISHLIST_REMOVE_LOADING,
  WISHLIST_REMOVE_RESET,
  WISHLIST_REMOVE_SUCCESS,
} from '../types';
import { WISH_LIST_LOCAL, CLEVERTAP_EVENTS, WISH_LIST_LOCAL_BOX } from '@app/utilities/constants';
import { ClevertapService } from '@app/services/clevertap.service';

export const loadingAdd = () => ({
  type: WISHLIST_ADD_LOADING,
});

export const errorAdd = () => ({
  type: WISHLIST_ADD_ERROR,
  payload: 'Unable to add to wishlist',
});

export const successAdd = () => ({
  type: WISHLIST_ADD_SUCCESS,
  payload: 'Added to wishlist',
});

export const resetAdd = () => ({
  type: WISHLIST_ADD_RESET,
});

export const loadingRemove = () => ({
  type: WISHLIST_REMOVE_LOADING,
});

export const errorRemove = () => ({
  type: WISHLIST_REMOVE_ERROR,
  payload: 'Unable to remove item from wishlist',
});

export const successRemove = () => ({
  type: WISHLIST_REMOVE_SUCCESS,
  payload: 'Item removed from wishlist',
});

export const resetRemove = () => ({
  type: WISHLIST_REMOVE_RESET,
});

export type PayloadRmvItmFromWishlist = {
  src: string;
  title: string;
  amount: string;
  compareAmt: string | undefined;
  url: string;
  id: string;
  browserUrl: string;
  productType: string | undefined;
  vendor: string | undefined;
}[];

export function removeItemFromWishlist(data: PayloadRmvItmFromWishlist, isBoxProduct: boolean) {
  return (dispatch: (arg0: { type: string; payload?: string }) => void) => {
    dispatch(loadingRemove());
    let arrayOld: Array = [];
    let objOld = '';

    console.log('ibp', isBoxProduct);
    if (isBoxProduct) {
      objOld = JSON.parse(localStorage.getItem(WISH_LIST_LOCAL_BOX));
    } else {
      objOld = JSON.parse(localStorage.getItem(WISH_LIST_LOCAL));
    }
    console.log('old Obj', objOld);
    if (objOld) {
      for (const item of data) {
        for (var x of objOld) {
          if (x.src === item.src && x.title === item.title && x.amount === item.amount && x.url === item.url) {
            //do not add
          } else {
            arrayOld.push(x);
          }
        }
      }
    }
    if (isBoxProduct) {
      localStorage.setItem(WISH_LIST_LOCAL_BOX, JSON.stringify(arrayOld));
    } else {
      localStorage.setItem(WISH_LIST_LOCAL, JSON.stringify(arrayOld));
    }
    dispatch(successRemove());
  };
}

export function addItemTowishlist(data: any, isBoxProduct: boolean) {
  return async (dispatch: (arg0: { type: string; payload?: string }) => void) => {
    dispatch(loadingAdd());
    console.log(data);
    let arrayOld = [];
    let objOld = '';
    if (isBoxProduct) {
      objOld = JSON.parse(localStorage.getItem(WISH_LIST_LOCAL_BOX));
    } else {
      objOld = JSON.parse(localStorage.getItem(WISH_LIST_LOCAL));
    }

    // console.log('old Obj', objOld);
    if (objOld) {
      for (var x of objOld) {
        arrayOld.push(x);
      }
    }
    if (data !== null) {
      for (const item of data) {
        let exist = 0;
        if (objOld) {
          for (var x of objOld) {
            if (x.src === item.src && x.title === item.title && x.amount === item.amount && x.url === item.url) {
              exist++;
            }
          }
        }
        if (exist == 0) {
          ClevertapService.recordEvent({
            eventName: CLEVERTAP_EVENTS.Wishlist,
            eventData: {
              'Product Type': item?.productType,
              'Brand Name': item?.vendor,
              'SKU Name': item?.title,
              'SKU ID': item?.id,
            },
          });
          arrayOld.push({
            src: item?.src,
            title: item?.title,
            amount: item?.amount,
            compareAmt: item?.compareAmt,
            url: item?.url,
            id: item?.id,
            browserUrl: item?.browserUrl,
            productType: item?.productType,
            vendor: item?.vendor,
          });
        }
      }
    }
    if (isBoxProduct) {
      localStorage.setItem(WISH_LIST_LOCAL_BOX, JSON.stringify(arrayOld));
    } else {
      localStorage.setItem(WISH_LIST_LOCAL, JSON.stringify(arrayOld));
    }
    dispatch(successAdd());
  };
}
