import React, { useEffect, useState } from 'react';
import NextLink, { LinkProps } from 'next/link';
import { connect } from 'react-redux';
import { useAppSelector } from '@app/state/hooks';

type Props = {
  children: React.ReactNode;
} & LinkProps;

/**
 * NextLink tag will be converted to html <a> anchor tag for mobile adaptablity
 */
export default function AdaptiveNextLink(props: Props) {
  const { href, children, ...restNextProps } = props;
  const isMobileWebView: boolean = useAppSelector((state) => state?.user?.isMobileWebView || false);

  if (isMobileWebView) {
    return (
      <a href={href.toString()} style={{ textDecorationLine: 'none' }}>
        {props.children}
      </a>
    );
  }
  return (
    <NextLink href={href} {...restNextProps}>
      {props.children}
    </NextLink>
  );
}
