export type MobileEvents =
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'add_to_wish'
  | 'change_cart_item_quantity'
  | 'add_byob_item'
  | 'update_byob_item_quantity'
  | 'shop_now'
  | 'skip_login'
  | 'logged_in'
  | 'logged_out'
  | 'chat_now'
  | 'profile_update'
  | 'order_complete'
  | 'shopify_customer_id'
  | 'cart_count'
  | 'show_lightbox'
  | 'sign_in'
  | 'show_byob_item_modal'
  | 'close_byob_item_modal'
  | 'added_to_cart'
  | 'navigate_back'
  | 'scroll'
  | 'login_state'
  | 'authSuccess'
  | 'copy_code'
  | 'skip_email'
  | 'schedule_hrv'
  | 'create_new_token'
  | 'clear_all_notify'
  | 'navigation'
  | 'set_hrv_context'
  | 'open_url'
  | 'home_training';

export type MobileScreens = 'QuizScreen' | 'LoginScreen' | 'HomeScreen' | 'start_hrv';

export type VariantSelectedOption = { name: string; value: string };
export interface MobileEventData {
  add_to_cart: { variantId: string; quantity: number };
  remove_from_cart: {
    variantId: string;
    isBox: boolean;
  };

  add_to_wish: {
    variantId: string;
    productId: string;
    selectedOptions: Array<VariantSelectedOption>;
    title: string;
    imgSrc: string | null;
  };

  change_cart_item_quantity: {
    quantity: number;
    variantId: string;
    type: 'zstore' | 'store';
  };

  add_byob_item: {
    quantity: number;
    variantId: string;
    productId: string;
    selectedOptions: Array<VariantSelectedOption>;
    title: string;
    imgSrc: string;
    categoryName?: string | null;
  };
  update_byob_item_quantity: {
    variantId: string;
    quantity: number;
  };
  shop_now: undefined;
  skip_login: undefined;
  logged_in: {
    uid: string;
    phone: string;
    name?: string;
    email?: string;
    userType?: string;
    customerId?: string;
  };
  login_state: {
    uid: string;
    phone: string;
    name?: string;
    email?: string;
    userType?: string;
    customerId?: string;
  };
  logged_out: undefined;
  profile_update: {
    phone: string;
    name: string;
    email: string;
  };
  chat_now: undefined;
  order_complete: { isBox: boolean };
  shopify_customer_id: { customerId: string };
  cart_count: { count: number; type: 'box' | 'cart' };
  show_lightbox: { images: Array<string>; title: string; clickedIndex: number };
  sign_in: undefined;
  show_byob_item_modal: { product_handle: string };
  close_byob_item_modal: undefined;
  added_to_cart: undefined;
  navigate_back: undefined;
  scroll: { scrollTop: number };
  authSuccess: { status: boolean };
  copy_code: { code: string };
  skip_email: undefined;
  schedule_hrv: {
    week_days: Array<string>;
    time: string;
  };
  create_new_token: undefined;
  clear_all_notify: undefined;
  navigation: {
    action: 'replace_screen' | 'push_screen' | 'pop_screen' | 'navigate' | 'home_screen' | 'home_training';
    data: { type?: 'webview' | 'native'; route: string; params?: any };
  };
  set_hrv_context: {
    hrvContext: 'normal_morning' | 'solve' | 'pre_training' | 'post_training' | 'normal_evening';
    nextRoute: string;
    routeType: string;
    routeData?: Object;
  };
  open_url: { webUrl: string };
  home_training: undefined;
}

export function postEventToMobile<T extends MobileEvents>(eventType: T, data: MobileEventData[T]) {
  if (!eventType) return;
  if (typeof window !== 'undefined') {
    console.log('boxxx', JSON.stringify({ type: eventType, data }));
    //@ts-expect-error
    if (window?.ReactNativeWebView) {
      //@ts-expect-error
      window?.ReactNativeWebView?.postMessage?.(JSON.stringify({ type: eventType, data }));
    } else {
      console.log(eventType, "-event couldn't sent to mobile, couldn't found webview");
    }
  }
}
