import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import { CART_ITEM_COUNT_QUERY } from '@app/constants/query.constant';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { Typography, Box, Divider } from '@mui/material';
import { ProductService } from '@app/services/product.service';
import Image from '@app/components/common/unOptimizedNextjsImage';
import reviewIcon from '../../../public/review.svg';
import NextLink from 'next/link';
import { COLORS, WISH_LIST_LOCAL, blurDataURL } from '@app/utilities/constants';
import {
  removeItemFromWishlist,
  addItemTowishlist,
  resetAdd,
  resetRemove,
  PayloadRmvItmFromWishlist,
} from '@app/state/action/wishlist';
import { connect } from 'react-redux';
import favouriteIcon from '../../../public/favourite.svg';
import favouritedIcon from '../../../public/favourited.svg';
import { CartService } from '@app/services/cart.service';
import { LoadingButton } from '@mui/lab';
import SaleImage from '../../../public/sale.svg';
import { CheckCookies } from '@app/utilities/cookiesCheck';
import AdaptiveNextLink from '../common/AdaptiveNextLink';
import useAddToCart from '@app/hooks/useAddToCart';
import { useThunkDispatch, useAppSelector } from '@app/state/hooks';
import useAdaptiveRouter from '@app/hooks/useAdaptiveRouter';

const authorImg = {
  Chetan: 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/CHETAN_CRM.png?v=1646638344',
  Sanjana: 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/Sanjana.png?v=1644817431',
  Arjun: 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/featured_100_x_100_px_14.png?v=1641548560',
  Ayushi: 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/Content_Writer_-_Ayushi.png?v=1639809247',
  'Manan Kadia': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/featured_100_x_100_px_10.png?v=1653899811',
  Shrijith:
    'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/3_86fe3ae5-e95d-4f90-9e63-3e16ab77fcdb.png?v=1653906428',
  'Snigdha Kumar': 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/snigdha_1.png?v=1643879174',
  'Aishwarya Kasera':
    'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/1_4377072c-06f2-47e9-828f-4bae8b7bcd80.png?v=1653906144',
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  src: string;
  title: string;
  amount: string;
  id: string;
  compareAmt: string | undefined;
  url: string;
  cstmStyle?: {};
  curated_by?: null | {};
  enableAuthor?: boolean;
  availableForSale?: boolean;
  tag?: string;
  productType?: string;
  vendor?: string;
  handle?: string;
  avgRating?: string;
  ratingCount?: string;
  isBoxProduct?: boolean;
}

function ProductCard({
  src,
  title,
  amount,
  compareAmt,
  url,
  curated_by,
  id,
  cstmWidth,
  enableAuthor,
  cstmStyle,
  availableForSale,
  tag,
  productType,
  vendor,
  handle,
  avgRating,
  ratingCount,
  isBoxProduct,
}: Props) {
  const router = useAdaptiveRouter();
  const thunkDispatch = useThunkDispatch();
  const wishlistAdd = useAppSelector((s) => s.wishlistAdd);
  const [expanded, setExpanded] = useState(false);
  const [toggleWishlist, setToggleWishlist] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeItmFromWishlist = (data: PayloadRmvItmFromWishlist) => {
    thunkDispatch(removeItemFromWishlist(data));
  };
  const addItmToWishlist = (data: PayloadRmvItmFromWishlist) => thunkDispatch(addItemTowishlist(data));

  const isDarkTheme = !!isBoxProduct;

  const handleToggleClick = async () => {
    if (CheckCookies()) {
      setToggleWishlist(!toggleWishlist);
      if (!toggleWishlist) {
        // if (id) {
        //   addItmToWishlist([
        //     {
        //       src,
        //       title,
        //       amount,
        //       compareAmt,
        //       url,
        //       id,
        //       browserUrl: typeof window !== undefined ? window?.location?.origin + url : url,
        //       productType,
        //       vendor,
        //     },
        //   ]);
        // } else {
        setLoading(true);
        let handleNew = url.replace('/products/', '').trim();
        const product = await ProductService.getSingle(handleNew);
        console.log('prod data', product);
        if (product?.variants[0]?.id) {
          addItmToWishlist([
            {
              src,
              title,
              amount,
              compareAmt,
              url,
              id: product?.variants[0]?.id,
              browserUrl: typeof window !== undefined ? window?.location?.origin + url : url,
              productType,
              vendor,
            },
          ]);
        }
        setLoading(false);
        // }
      } else {
        removeItmFromWishlist([
          {
            src,
            title,
            amount,
            compareAmt,
            url,
            id,
            browserUrl: typeof window !== undefined ? window?.location?.origin + url : url,
            productType,
            vendor,
          },
        ]);
      }
    }
  };

  const addItem = useAddToCart(false);

  useEffect(() => {
    if (!navigator?.cookieEnabled) {
      return;
    }
    let objOld = JSON.parse(localStorage.getItem(WISH_LIST_LOCAL)),
      exist = 0;
    if (objOld !== null) {
      for (var x of objOld) {
        if (x.src === src && x.title === title && x.amount === amount && x.url === url) {
          exist++;
        }
      }
      if (exist > 0) {
        setToggleWishlist(true);
      } else {
        setToggleWishlist(false);
      }
    }
  }, []);

  const handleAddToCart = async () => {
    if (id) {
      await addItem.mutateAsync({
        variantId: id,
        quantity: 1,
      });
    } else {
      setLoading(true);
      let handleNew = url.replace('/products/', '').trim();
      // getProduct(handleNew);
    }
  };

  const getProduct = async (handleNew: string) => {
    const product = await ProductService.getSingle(handleNew);
    if (product?.variants[0]?.id) {
      await addItem.mutateAsync({
        variantId: product?.variants[0]?.id,
        quantity: 1,
      });
    }
    setLoading(false);
  };

  let calDiscount =
    compareAmt && Number(compareAmt)
      ? ((parseFloat(compareAmt) - parseFloat(amount)) / parseFloat(compareAmt)) * 100
      : '';

  const CardTheme = {
    container: {
      width: cstmWidth ? cstmWidth : { xs: 260, sm: 300 },
      m: '15px',
      borderRadius: '12px',
      border: '1px solid #E9EAEA',
      boxShadow: 'none',
      userSelect: 'none',
      ...(cstmStyle && cstmStyle),
      position: 'relative',
      ...(isDarkTheme && { background: '#101010' }),
    },
    title: {
      fontSize: '14px',
      marginTop: '8px',
      height: '40px',
      marginBottom: '5px',
      fontWeight: '500',
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      ...(isDarkTheme && { color: COLORS.white }),
    },
    amount: { fontWeight: 'bold', fontSize: { xs: '14px', sm: '16px' }, ...(isDarkTheme && { color: COLORS.white }) },
    compareAmt: {
      fontWeight: 'normal',
      color: '#556166',
      textDecorationLine: 'line-through',
      ml: { xs: '4px', sm: '8px' },
      fontSize: { xs: '12px', sm: '16px' },
      ...(isDarkTheme && { color: COLORS.lightGrey }),
    },
  };

  return (
    <Card sx={CardTheme.container} onMouseOver={() => setShowButton(true)} onMouseLeave={() => setShowButton(false)}>
      <AdaptiveNextLink href={url} passHref>
        <Box
          component={'a'}
          // onClick={() => {
          //   router.push(url);
          // }}
          sx={{
            cursor: 'pointer',
            // background: 'red',
            position: 'absolute',
            width: '100%',
            zIndex: 1,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </AdaptiveNextLink>
      <Grid container justifyContent={'space-between'}>
        <Grid item paddingLeft={'10px'} paddingTop="10px" sx={{ height: '40px' }}>
          {availableForSale && (
            <Image priority src={SaleImage} width={'40px'} height={'40px'} alt="favouritedIcon img" />
          )}
        </Grid>

        <Grid item component={IconButton} sx={{ zIndex: 2 }} onClick={handleToggleClick} aria-label="add to favorites">
          {/* <IconButton sx={{ zIndex: 2 }} onClick={handleToggleClick} aria-label="add to favorites"> */}
          <Image
            priority
            src={toggleWishlist ? favouritedIcon : favouriteIcon}
            width={'24px'}
            height={'24px'}
            alt="favouritedIcon img"
          />
          {/* </IconButton> */}
        </Grid>
      </Grid>
      <Box
        sx={{
          position: 'relative',
          width: { xs: '120px', sm: '144px' },
          height: { xs: '120px', sm: '144px' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        {src && (
          <Image placeholder="blur" blurDataURL={blurDataURL} alt={title} src={src} layout="fill" objectFit="contain" />
        )}
      </Box>
      <CardContent sx={{ padding: { xs: '10px', sm: '24px' } }}>
        <Typography
          sx={{ fontSize: '10px', color: '#9EACB2', textTransform: 'uppercase', fontWeight: 'bold' }}
          noWrap
          variant="body2"
        >
          {vendor}
        </Typography>

        <Typography sx={CardTheme.title} variant="subtitle2" color="text.primary">
          {title}
        </Typography>

        {/* PRODUCT TAG bottom margin should be 26px if enabling add to cart button */}

        <Typography
          component={'span'}
          sx={{
            fontFamily: 'Public Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '10px',
            lineHeight: '12px',
            color: '#17719B',
            background: '#FFFFFF',
            border: '1px solid #E9EAEA',
            boxSizing: 'border-box',
            borderRadius: '4px',
            padding: '4px 6px',
            display: 'inline',
            ...(!tag && { border: 'none' }),
          }}
          variant="body2"
          color="text.primary"
        >
          {tag}
        </Typography>

        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mt: '8px', height: '20px' }}>
          {avgRating && ratingCount && (
            <>
              <Box
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  borderColor: '#E9EAEA',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  padding: '3px',
                  marginRight: '6px',
                  borderRadius: '4px',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: '12px', color: '#556166', marginRight: '3px' }}
                  color="text.primary"
                >
                  {Number(avgRating).toFixed(1)}
                </Typography>
                <Image src={reviewIcon} alt="account icon" />
              </Box>

              <Typography variant="body2" sx={{ fontSize: '12px', color: '#556166' }}>
                {`(` + ratingCount + ` Reviews)`}
              </Typography>
            </>
          )}
        </Box>

        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mt: '8px', marginTop: '15px' }}>
          <Typography variant="body2" sx={CardTheme.amount} color="text.primary">
            {`₹${amount}`}
          </Typography>
          {!!Number(calDiscount) && (
            <Typography variant="body2" sx={CardTheme.compareAmt} color="text.primary">
              {`₹${compareAmt}`}
            </Typography>
          )}
          {!!Number(calDiscount) && (
            <Typography
              variant="body2"
              sx={{
                fontWeight: '500',
                color: { xs: '#FC9132', sm: '#FFFFFF' },
                background: { xs: '#FFFFFF', sm: '#FC9132' },
                padding: { xs: '0px', sm: '3px 6px 3px 6px' },
                ml: { xs: '4px', sm: '8px' },
                fontSize: '11px',
                borderRadius: '5px',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
              color="text.primary"
            >
              {`${Number(calDiscount).toFixed(0)}% OFF`}
            </Typography>
          )}
        </Box>
        <Box sx={enableAuthor ? { marginTop: '19px', height: '143px' } : {}}>
          {curated_by && enableAuthor && (
            <>
              <Divider />
              <Box sx={{ marginTop: '16px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Image
                  placeholder="blur"
                  blurDataURL={blurDataURL}
                  alt="avatar"
                  width={'40px'}
                  height={'40px'}
                  src={authorImg[curated_by?.author] ? authorImg[curated_by?.author] : authorImg.Arjun}
                />
                <Box sx={{ marginLeft: '8px' }}>
                  <Typography
                    sx={{ fontWeight: '600', fontSize: '12px' }}
                  >{`Recommended by ${curated_by?.author}`}</Typography>
                  <Typography sx={{ color: COLORS.grey, fontSize: '12px' }}>{curated_by?.post}</Typography>
                </Box>
              </Box>

              <Typography
                sx={{
                  background: '#FFF5F0',
                  borderRadius: '8px',
                  borderTopLeftRadius: '0px',
                  padding: '10px 12px 0px 12px',
                  marginTop: '12px',
                  borderBottom: 10,
                  borderColor: '#FFF5F0',
                  fontStyle: 'italic',
                  fontSize: '12px',
                  fontWeight: '500',
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}
              >
                {curated_by?.quote}
              </Typography>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProductCard;
