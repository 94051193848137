import { useAppSelector } from '@app/state/hooks';
import Url, { useRouter, NextRouter } from 'next/router';
import type TransitionOptions from 'next/router';
import { UrlObject } from 'url';

//to be use to fire mobile-webview navigation-state-change
async function changeWindowRoute(url: UrlObject | string) {
  console.log('route info', url);
  let urlToRoute = '';
  if (typeof url == 'string') {
    urlToRoute = url;
  } else if (url.pathname) {
    urlToRoute = url.pathname?.toString();
  }
  window.location.href = urlToRoute;
  return true;
}

//to be use to fire mobile-webview navigation-state-change
async function replaceWindowRoute(url: UrlObject | string) {
  console.log('route info', url);
  let urlToRoute = '';
  if (typeof url == 'string') {
    urlToRoute = url;
  } else if (url.pathname) {
    urlToRoute = url.pathname?.toString();
  }
  window.location.replace(urlToRoute);
  return true;
}

export default function useAdaptiveRouter(): NextRouter {
  const router = useRouter();
  const isMobileWebView: boolean = useAppSelector((state) => state?.user?.isMobileWebView || false);
  if (isMobileWebView && window?.location) {
    router.push = changeWindowRoute;
    router.replace = replaceWindowRoute;
    router.reload = window.location.reload;
  }
  return router;
}
